.navbar-brand {
    font-family: 'Tilt Neon';
    font-size: 32px;
    padding: 0;
    line-height: 1;
}

.navbar-brand span {
    color: #a31616;
}

.navbar-nav {
    .nav-item {
        margin: 0 10px;

        .nav-link {
            text-transform: uppercase;
            font-size: 14px;
            padding-bottom: 0;
            position: relative;
            padding: 0;
            color: #fff;

            &.active {
                color: var(--color-text-accent);
            }
        }
    }

}


.navbar {
    z-index: 11;
    position: fixed;
    width: 100%;
}

.navbar .navbar-container {
    align-items: flex-end;
    padding-top: 6px;
    padding-bottom: 6px;
}

.navbar .navbar-logout a {
    color: #fff;
    text-decoration: none;
    background: transparent;
}

.navbar .navbar-logout a i {
    font-size: 17px;
    margin-left: 5px;
}

@media (min-width: 992px) {
    .hidden-lg {
        display: none !important;
    }
}

@media (max-width: 991.99px) {
    .hidden-md {
        display: none !important;
    }

    .navbar-brand {
        order: 1;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .navbar-toggler {
        order: 0;
        margin-right: 60px;
    }

    .navbar-account {
        order: 1;
    }

    .navbar-collapse {
        position: absolute;
        top: 60px;
        background: #212529;
        box-shadow: 0 5px 18px #0000003d;
        padding: 10px;
        border-radius: 6px;
        left: 0;
        width: 400px;
        max-width: 95%;
        padding: 30px 10px;
    }

    .navbar-nav .nav-item .nav-link {
        font-size: 18px;
    }

    .navbar-nav .nav-item .nav-link svg {
        margin-right: 8px;
    }

    .navbar-nav .nav-item .nav-link i {
        margin-right: 8px;
        width: 20px;
        display: inline-block;
    }

    .navbar-nav .nav-item {
        margin: 20px;
        width: fit-content;
    }

    .btn-group .dropdown-menu {
        right: -12px;
    }

    .dropdown-item {
        font-size: 18px;
    }

    .navbar .navbar-container {
        align-items: center;
    }
}

@media (max-width: 767px) {
    .navbar-toggler {
        margin-right: 0;
    }
}