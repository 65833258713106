.courses-template {
    margin-top: 40px;
}

.course-image svg {
    width: 100%;
    height: auto;
}

.course-info small {
    display: flex;
}

.course-lessons {
    font-weight: 500;
    margin-left: 10px;
    font-size: 12px;
}

.course-item .course-title {
    text-decoration: none;
    color: #2b3035;
    font-size: 20px;
    position: relative;
    font-weight: 600;
}

.course-item .course-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--blue-color);
}

.course-trainer {
    font-weight: 500;
}

.course-trainer span {
    text-transform: capitalize;
    font-weight: 700;
    color: var(--blue-color);
}

.courses-list .course-short-details-link.button {
    margin: 10px 0 0;
}

@media screen and (max-width: 768px) {
    .courses-template {
        margin-top: 20px;
        width: calc(100% - 20px);
    }

    .course-image {
        margin-bottom: 15px;
    }
}