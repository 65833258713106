.home-tasks--course {
    margin-top: 25px;
}

.course-progress .progress-line {
    width: 180px;
    height: 8px;
    background-color: #dcdcdcb0;
    border-radius: 25px;
    border: 1px solid #adadad;
}

.course-progress .progress-line .progress-line-amount {
    height: 100%;
    background-color: #a31616;
    border-radius: 25px;
}

.course-progress .progress-text {
    font-size: 11px;
    text-align: right;
    font-weight: 500;
}

.home-tasks .home-tasks--course .course-title{
    text-decoration: none;
    color: #2b3035;
    font-size: 20px;
    position: relative;
    font-weight: 600;
}

.home-tasks .home-tasks--course .course-title::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--blue-color);
}

.home-tasks .course-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}


.course-progress {
    margin-left: 30px;
}

.home-tasks .home-tasks--course .course-home-tasks th {
    text-transform: uppercase;
    font-size: 10px;
}

.home-tasks .home-tasks--course .course-home-tasks td {
    font-size: 12px;
    vertical-align: middle;
}

.home-tasks .home-tasks--course .course-home-tasks td a {
    text-decoration: none;
    color: var(--blue-color);
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    transition: all 0.25s linear;
}

.home-tasks .home-tasks--course .course-home-tasks td a:hover {
    text-decoration: underline;
}

.home-tasks .home-tasks--course .course-home-tasks td .task-title {
    text-transform: uppercase;
    font-weight: 700;
}

.home-tasks .home-tasks--course .course-home-tasks td.task-state p {
    margin: auto;
    width: fit-content;
    font-size: 10px;
    font-weight: 600;
    padding: 2px 8px;
    border: 1px solid;
    border-radius: 17px;
}

.home-tasks .home-tasks--course .course-home-tasks td.task-state .task-state-completed {
    background: #b8dab8;
    border-color: #33ab33;
    color: #226d22;
}

.home-tasks .home-tasks--course .course-home-tasks td.task-state .task-state-not-completed {
    background: #aabfdf;
    border-color: #5e7fad;
    color: #1e5bb4;
}

.home-tasks .home-tasks--course .course-home-tasks td.task-score.task-score--finished {
    font-weight: 600;
}

.home-tasks .home-tasks--course .course-home-tasks {
    --bs-table-border-color: #bcb3b4;
    --bs-table-bg: #fff;
}


@media screen and (max-width: 1024px) {
    .home-tasks .course-header {
        flex-direction: column;
        align-items: self-start;
        padding: 0;
    }

    .home-tasks .home-tasks--course .course-title {
        width: 100%;
        text-align: left;
        width: fit-content;
        padding: 0;
    }

    .table-responsive {
        padding: 0;
    }

    .course-progress {
        margin: 10px 0px;
        width: 100%;
    }

    .tasks-filters {
        flex-direction: column;
        margin: 0;
        width: 100%;
    }

    .select-container {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .select-wrapper, .select-wrapper select {
        width: 100%;
    }

    .course-progress .progress-line {
        width: 100%;
    }

    .home-tasks .home-tasks--course .course-home-tasks td a {
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .home-tasks .home-tasks--course .course-home-tasks td a {
        font-size: 11px;
    }

    .home-tasks .home-tasks--course .course-home-tasks td {
        font-size: 10px;
    }

    .home-tasks .home-tasks--course .course-home-tasks td.task-state p.task-state-completed {
        font-size: 8px;
        white-space: nowrap;
    }
}