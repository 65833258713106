
.rate {
    float: left;
    height: 46px;
    max-width: 330px;
    margin-left: -33px;
}

.rate:not(:checked) > input {
    position: absolute;
    top: -9999px;
}

.rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc;
}

.rate:not(:checked) > label:before {
    content: '★ ';
}

.rate > input:checked ~ label {
    color:var(--blue-color);
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: var(--light-blue-color)
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: var(--blue-color);
}

/* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */