.white-wrap {
    padding: 25px;
    background: white;
    border: 1px solid #e7e9ec;
    border-radius: 10px;
}

.profile-template {
    margin-top: 40px;
}

.profile-nav {
    width: 25%;
    margin-bottom: 20px;
}

.profile-content {
    width: 70%;
}

.profile-title {
    text-decoration: none;
    color: #2b3035;
    font-size: 26px;
    margin-bottom: 20px;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    width: fit-content;
}

.profile-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--blue-color);
}

.profile-nav-item {
    margin: 10px 0;
}

.profile-nav-item a {
    display: block;
    color: #2b3035;
    font-size: 17px;
    font-weight: 600;
    position: relative;
}

.profile-nav-item a:hover {
    color: #a31616;
}

.profile-nav-item a.active {
    color: #a31616;
    padding-left: 15px;
}

.profile-nav-item a.active::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    top: 5px;
    left: -3px;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M15.1997 10.4919L13.2297 8.52188L10.0197 5.31188C9.33969 4.64188 8.17969 5.12188 8.17969 6.08188V12.3119V17.9219C8.17969 18.8819 9.33969 19.3619 10.0197 18.6819L15.1997 13.5019C16.0297 12.6819 16.0297 11.3219 15.1997 10.4919Z' fill='%23a31616'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.profile-content .button.primary {
    margin-top: 20px;
}

.info-label {
    font-weight: 600;
}

.profile-form {
    font-size: 16px;
}

.profile-content-title {
    margin: 0;
    padding: 10px 0;
}

.profile-form input {
    min-width: 50%;
}

.profile-content .js-save-cancel-form {
    margin: 0;
}

.profile-content .js-save-cancel-form .button.primary {
    border-radius: 0;
    margin-top: 10px;
}

.profile-content .js-save-cancel-form .button.btn-underline {
    margin-left: 20px;
}

@media (max-width: 991.99px) {
    .profile-nav {
        width: 100%;
    }
    
    .profile-content {
        width: 100%;
    }

    .profile-content-title {
        font-size: 23px;
        padding: 12px 0;
    }

    .info-label {
        padding: 0;
    }

    .profile-form {
        font-size: 14px;
    }

    .profile-form input {
        min-width: 75%;
    }
}

@media (max-width: 767px) {
    .validation-messages {
        margin-top: 10px;
    }

    .profile-form input {
        min-width: unset;
        width: 100%;
    }
}