body {
    font-family: 'Montserrat';
    font-size: 14px;
    //background: #fafbfd;
    min-height: 100vh;
    color: #2b3035;
}

p {
    margin: 0;
    font-size: 14px;
}

a {
    text-decoration: none;
    color: var(--blue-color);
    text-decoration-color: var(--blue-color);
    transition: all 0.25s linear;
    position: relative;
}

a.hover-animation::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--blue-color);;
    transition: all 0.25s linear;
}

a.hover-animation:hover::after {
    width: 100%;
}

h1 {
    color: #2b3035;
    font-size: 30px;
    font-weight: 500;
    margin: 0;
}

h3 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 11px; 
}

h4 {
    font-size: 22px;
    font-weight: 600;
}

svg {
    &:not(.logo) {
        width: 20px;
        height: 20px;
    }
}

.page-additional-title {
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
}

.page-additional-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color:  var(--blue-color);
}

.page-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.main-container {
    margin-top: 40px;
    margin-bottom: 20px;
}

.breadcrumbs {
    margin-bottom: 10px;
}

.breadcrumbs .breadcrumbs-divider{
    font-family: cursive;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    color: #8b8b8b;
    margin: 0 3px;
}

.breadcrumbs a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
}

.button {
    display: block;
    width: fit-content;
    text-decoration: none;
    padding: 5px 15px;
    margin: 10px 0;
    font-weight: 600;
    text-transform: capitalize;
    appearance: none;
    background: none;
    transition: all 0.25s linear;

    &.primary {
        background: #a31616;
        color: #fff !important;
        border: 2px solid #a31616;

        &:hover {
            background: transparent;
            color: #a31616 !important;
        }

    }

    &.transparent {
        border: 2px solid #a31616;
        color: #a31616;
    }

}


.button.primary:disabled {
    opacity: .5;
}

.button.primary:disabled:hover {
    background: #a31616;
    color: #fff;
}

.button.btn-underline {
    appearance: none;
    text-decoration: underline;
    color: #a31616;
    text-decoration-color: #a31616;
    transition: all 0.25s linear;
    position: relative;
    font-weight: 600;
    width: fit-content;
    padding: 5px 15px;
    margin: 10px 0;
    border: none;
}

.button.pulse {
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    transition: all 300ms ease-in-out;
}


.button.pulse:hover {
    -webkit-animation: none;
    -moz-animation: none;
    -ms-animation: none;
    animation: none;
}

.color-red {
    color: #a31616;
}

.color-green {
    color: #226d22;
}

.validation-true {
    color: #226d22;
    display: flex;
}

.validation-false {
    color: #a31616;
    display: flex;
}

.validation-true::before {
    content: "";
    display: inline-block;
    width: 17.5px;
    height: 17px;
    margin: 1px 2px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24.00 24.00' fill='none' xmlns='http://www.w3.org/2000/svg' transform='rotate(0)'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M4.89163 13.2687L9.16582 17.5427L18.7085 8' stroke='%23226d22' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.validation-false::before {
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.9498 8.46447C17.3404 8.07394 17.3404 7.44078 16.9498 7.05025C16.5593 6.65973 15.9261 6.65973 15.5356 7.05025L12.0001 10.5858L8.46455 7.05025C8.07402 6.65973 7.44086 6.65973 7.05033 7.05025C6.65981 7.44078 6.65981 8.07394 7.05033 8.46447L10.5859 12L7.05033 15.5355C6.65981 15.9261 6.65981 16.5592 7.05033 16.9497C7.44086 17.3403 8.07402 17.3403 8.46455 16.9497L12.0001 13.4142L15.5356 16.9497C15.9261 17.3403 16.5593 17.3403 16.9498 16.9497C17.3404 16.5592 17.3404 15.9261 16.9498 15.5355L13.4143 12L16.9498 8.46447Z' fill='%23a31616'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

/* Animation */

@-webkit-keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}

@-moz-keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}

@-ms-keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}

@keyframes pulsing {
    to {
        box-shadow: 0 0 0 20px rgba(232, 76, 61, 0);
    }
}


.form-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

input:not([type="file"]), textarea {
    border: 1px solid #e4e0e0;
    outline: none;
    padding: 10px 15px;
    border-radius: 7px;
    font-size: 15px;
}

textarea {
    min-height: 90px;
}

label {
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: 500;
}

.hidden {
    display: none;
}

.wrap {
    background: white;
    padding: 15px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    margin-bottom: 20px;
}

.select-container label {
    margin: 0 5px 0 0;
    font-size: 12px;
}

.select-wrapper {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z' fill='%23a31616'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position-x: 98%;
    background-position-y: center;
    background-color: white;
    border-radius: 5px;
}

.select-container {
    display: flex;
    align-items: center;
}

.select-wrapper select {
    border: 1px solid #e4e0e0;
    outline: none;
    border-radius: 5px;
    padding: 5px 17px 5px 5px;
    font-size: 12px;
    text-transform: capitalize;
    appearance: none;
    background: transparent;
}

.btn-group .btn-danger {
    --bs-btn-bg: #a31616;
    --bs-btn-border-color: #a31616;
}

.header-account .btn,
.header-account .btn:first-child:active {
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
    padding: 0;
    color: #fff;
    border: none;
}

.header-account .btn svg path {
    fill: #d22727;
}

.btn-group .dropdown-menu {
    bottom: -95px;
}

.header-account .dropdown-toggle::after {
    font-size: 20px;
    vertical-align: 2px;
}

.accordion-item, .accordion-item:first-of-type .accordion-button {
    --bs-accordion-border-radius: 0;
    --bs-accordion-btn-focus-box-shadow: transparent;
    --bs-accordion-inner-border-radius: 0;
    --bs-accordion-active-bg: #a316161a;
}

.accordion .accordion-item {
    border: 1px solid #dee2e6;
    margin-bottom: 15px;
}

.accordion .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z' fill='%23a31616'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 23px;
    background-position-x: 98%;
    background-position-y: center;
    position: absolute;
    right: 18px;
}

.accordion-item .accordion-header .accordion-button {
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    padding-right: 50px;
    border: 1px solid #e7e9ec;
}

.accordion-item .accordion-header.task-done .accordion-button{
    background: #f0f9f0;
    color: #226d22;
}

.accordion-item .accordion-header.task-not-done .accordion-button{
    background: #ffffff;
}

.accordion-item .accordion-header.task-not-done.answer-sent .accordion-button{
    background: #fff6d9;
    color: #cf9e09;
}

.accordion-item .accordion-header.task-done .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z' fill='%23226d22'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.accordion-item .accordion-header.task-not-done.answer-sent .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M5.70711 9.71069C5.31658 10.1012 5.31658 10.7344 5.70711 11.1249L10.5993 16.0123C11.3805 16.7927 12.6463 16.7924 13.4271 16.0117L18.3174 11.1213C18.708 10.7308 18.708 10.0976 18.3174 9.70708C17.9269 9.31655 17.2937 9.31655 16.9032 9.70708L12.7176 13.8927C12.3271 14.2833 11.6939 14.2832 11.3034 13.8927L7.12132 9.71069C6.7308 9.32016 6.09763 9.32016 5.70711 9.71069Z' fill='%23cf9e09'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.accordion-header .task-score {
    font-size: 10px;
    font-weight: 500;
    text-transform: lowercase;
    margin-top: 3px;
    margin-left: 5px;
}

.accordion-header svg {
    margin-right: 5px;
    margin-bottom: 2px;
}

.accordion-header.task-not-done.answer-sent svg {
    height: 16px;
}

.accordion-header.task-not-done svg {
    height: 16px;
}

.accordion-header.task-done svg path {
    fill: #226d22;
}

.accordion-header.task-not-done.answer-sent svg path {
    fill: #cf9e09;
}

.accordion-header .task-status .task-state {
    text-transform: capitalize;
}

.accordion-header .task-status-score .score {
    font-size: 14px;
}

.accordion-header .task-status-score .task-price {
    font-size: 14px;
}

.accordion-body .task-content {
    width: 100%;
}

.accordion-body .task-answer-form {
    margin-top: 30px;
    width: 100%;
    max-width: 500px;
}

.accordion-body .task-answer-form input:not([type="file"]), 
.accordion-body .task-answer-form textarea {
    padding: 7px 10px;
    font-size: 12px;
}

.accordion-body .task-answer-form textarea {
    height: 90px;
}

.accordion-body .task-answer-form .drop-zone {
    height: 56px;
    font-size: 12px;
}

.big-width-td {
    min-width: 180px;
}

.password-wrapper {
    width: 100%;
    position: relative;
}

.password-wrapper input {
    width: 100%;
}

.show-password {
    cursor: pointer;
    position: absolute;
    right: 12px;
    z-index: 9;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.6;
    transition: all .25s linear;
}

.show-password:hover {
    opacity: 1;
}

.student-template {
    padding-top: 60px;
    position: relative;
}

.flex-center {
    display: flex;
    align-content: center;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .main-container {
        padding: 0 22px;
        margin-top: 20px;
    }

    .big-width-td {
        min-width: 88px;
    }

    .hidden-on-md {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .row {
        margin: auto;
    }
}

@media screen and (max-width: 767px) {
    .breadcrumbs a {
        font-size: 11px;
    }

    .page-header h1 {
        font-size: 23px;
    }

    .page-header .task-score {
        display: block;
    }

    .page-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .page-header .task-status {
        margin-top: 10px;
        justify-content: space-between;
        width: 100%;
    }

    .back-btn svg {
        margin-bottom: 3px;
    }

    h1 {
        font-size: 23px;
    }

    .hidden-on-sm {
        display: none;
    }

    .big-width-td {
        min-width: 76px;
    }
}

.hide {
    display: none;
}

.mr-5 {
    margin-right: 5px !important;
}
